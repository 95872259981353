.RedditOAuth {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.EOA {
    margin-bottom: 20px;
    width: 80%;
}