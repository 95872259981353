.score {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.comment-upvote:hover,
.comment-downvote:hover {
  cursor: pointer;
}
