.App {
  text-align: center;
}

.App-header {
  background-color: #2d2d2e;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  font-size: calc(10px + 2vmin);
  color: #efeff1;
}

.donation {
  margin: 2vh 0px 1vh 0px;
  font-size: 1.4rem;
}

.donation img:hover {
  cursor: pointer;
}

.coffee img{
  width: 6vw;
  height: 2.2vh;
  border-radius: 3px;
  object-fit: cover;
}
