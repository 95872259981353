.RedditTwitchChat-Wrapper {
  margin: 0 30px;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.4);
  display: flex;
  flex-direction: column;
  position: relative;
}

.top-bar-wrapper {
  width: 100%;
  height: 4.5vh;
  border-bottom: 2px solid hsla(0, 0%, 100%, 0.1);
}

.top-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.9em;
}

.RedditTwitchChat {
  max-width: 60vw;
  max-height: 80vh;
  overflow: scroll;
  overflow-x: hidden;
  scrollbar-width: none;
}

.rtc-header {
  display: flex;
  width: 100%;
  margin-top: 10px;
  border-bottom: 2px solid hsla(0, 0%, 100%, 0.1);
}

.score-wrapper {
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  font-size: 1.3rem;
  margin: 0 10px 0 10px;
  padding-top: 3px;
}

.rtc-trash,
.rtc-plus {
  height: 5vh;
  width: 5vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.rtc-trash:hover,
.rtc-plus:hover {
  cursor: pointer;
}

.post {
  width: 100%;
  text-align: left;
  margin: 0 10px 30px 0;
}

.post-name {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 10px;
}

.post-selftext {
  font-size: 1.3rem;
}

.post-selftext img {
  width: 100%;
  object-fit: cover;
}

.post-comments-wrapper {
  padding: 0 10px 0 10px;
  margin-bottom: 15px;
  position: relative;
}

.s2t-wrapper {
  position: -webkit-sticky;
  position: sticky;
  z-index: 0;
  height: 0;
  width: 0;
  left: 89%;
  top: 94%;
}

.scroll-to-top {
  width: 4vh;
  height: 4vh;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.7rem;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.4);
  background-color: #2d2d2e;
  color: #4fbcff;
}

.scroll-to-top:hover {
  cursor: pointer;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.4);
}

.chat-box-wrapper {
  width: 100%;
  min-height: 7vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #2d2d2e;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.4);
}

.chat-box {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.chat-wrapper {
  margin-left: 10px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.chat-wrapper textarea {
  width: 90%;
  min-height: 5vh;
  resize: none;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  background-color: #2d2d2e;
  border: none;
  color: #efeff1;
  caret-color: #efeff1;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.4);
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.chat-wrapper textarea:focus {
  min-height: 9vh;
}

.send-wrapper {
  width: 10%;
  height: 7vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.send {
  color: #efeff1;
  background-color: transparent;
  border: none;
  min-height: 5vh;
  width: 75%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.4);
}

.send:hover {
  cursor: pointer;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.4);
}

/* Hide scrollbar for Chrome, Safari and Opera */

::-webkit-scrollbar {
  display: none;
}
