.addRTC-wrapper {
  min-width: 30vw;
  height: 95vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.addRTC {
  height: 60vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.4);
}

.link-input-text {
  font-size: 2rem;
  text-overflow: ellipsis;
}

.link-text-input {
  width: 99%;
  border-bottom: 2px solid #ffffff;
  margin: 30px 0;
}

.link-text-input input {
  border: none;
  background: none;
  width: 100%;
  outline: none;
  color: #aaaaaa86;
  padding: 0 5px;
  height: 40px;
}

.link-text-input input:hover {
  caret-color: auto;
  color: #aaaaaa;
}

.link-submit {
  position: absolute;
  left: -9999px;
}
