.Home {
  caret-color: transparent;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}

.RTCWrappers {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 2vh;
}
